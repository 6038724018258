import { Environment } from '../env';
import { ErrorMessage } from '@components/errorMessage';
import { Formik } from 'formik';
import { NumberOfEmployeesDropdown } from '@components/numberOfEmployeesDropdown';
import { RegisterTierForm } from '@unternehmensverzeichnis/shared/types';
import { SalutationDropdown } from '@components/salutationDropdown';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback } from 'react';
import TextInput from '@components/textInput';

export default function GoldSilverForm() {
    const [params] = useSearchParams();
    const [error, setError] = React.useState<string | null>(null);
    const [confirmedDataProtection, setConfirmedDataProtection] =
        React.useState<boolean>(false);
    const tier = params.get('mitgliedschaft');
    const navigate = useNavigate();

    const handleSubmit = useCallback(async (values: RegisterTierForm) => {
        const tierValues = { ...values, tier };
        const result = await fetch(
            `${Environment.BACKEND_BASE_URL}/registerTier`,
            {
                body: JSON.stringify(tierValues),
                method: 'POST',
            },
        );
        if (result.ok) {
            navigate('/registrierung-erfolgreich');
            setError(null);
        } else {
            const jsonResponse = await result.text();
            setError(jsonResponse || 'error');
        }
    }, []);

    if (tier !== 'gold' && tier !== 'silber') {
        return <ErrorMessage error={new Error('tier not valid')} />;
    }
    const isGold = tier === 'gold';

    return (
        <>
            <h1 className="w-full text-center mb-12">
                {isGold
                    ? 'Anmeldung für die Gold-Mitgliedschaft'
                    : 'Anmeldung für die kostenlose Silbermitgliedschaft'}
            </h1>
            {isGold && (
                <p className="text-2xl mb-12 font-light">
                    Melden Sie sich jetzt an und genießen Sie alle Vorteile des
                    exklusiven BIG BANG KI-Circles.
                </p>
            )}
            <Formik<RegisterTierForm>
                initialValues={{
                    salutation: undefined,
                    title: undefined,
                    firstName: undefined,
                    lastName: undefined,
                    email: undefined,
                    telephone: undefined,
                    companyNameWithLegalForm: undefined,
                    industry: undefined,
                    numberOfEmployees: undefined,
                    jobTitle: undefined,
                }}
                validate={(values) => {
                    const errors: RegisterTierForm = {};
                    if (!values.email) {
                        errors.email = 'E-Mail darf nicht leer sein.';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email,
                        )
                    ) {
                        errors.email = 'Invalide E-Mail Adresse';
                    }
                    if (!values.firstName) {
                        errors.firstName = 'Vorname darf nicht leer sein.';
                    }

                    if (!values.lastName) {
                        errors.lastName = 'Nachname darf nicht leer sein.';
                    }
                    if (!values.companyNameWithLegalForm) {
                        errors.companyNameWithLegalForm =
                            'Unternehmen mit Firmierung darf nicht leer sein.';
                    }
                    if (!values.industry) {
                        errors.industry = 'Branche darf nicht leer sein.';
                    }
                    if (!values.jobTitle) {
                        errors.jobTitle =
                            'Berufsbezeichnung darf nicht leer sein.';
                    }
                    if (!values.telephone) {
                        errors.telephone =
                            'Telefonnummer darf nicht leer sein.';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values).finally(() => setSubmitting(false));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-4 gap-4 space-y-2 w-full my-4">
                            <SalutationDropdown disabled={isSubmitting} />
                            <TextInput
                                label="Titel"
                                value={values.title ?? ''}
                                touched={touched.title}
                                error={errors.title}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                id="title"
                                name="title"
                                type="text"
                                containerClassName="col-span-4 md:col-span-1"
                            />
                            {/* empty object to have an empty cell */}
                            <div className="hidden md:block col-span-2" />
                            <TextInput
                                label="Vorname*"
                                value={values.firstName ?? ''}
                                touched={touched.firstName}
                                error={errors.firstName}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                id="firstName"
                                name="firstName"
                                type="text"
                                containerClassName="col-span-4 md:col-span-2"
                            />
                            <TextInput
                                label="Nachname*"
                                value={values.lastName ?? ''}
                                touched={touched.lastName}
                                error={errors.lastName}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                id="lastName"
                                name="lastName"
                                type="text"
                                containerClassName="col-span-4 md:col-span-2"
                            />
                            <TextInput
                                label="E-Mail*"
                                value={values.email ?? ''}
                                touched={touched.email}
                                error={errors.email}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                id="email"
                                name="email"
                                type="email"
                                containerClassName="col-span-4 md:col-span-2"
                            />
                            <TextInput
                                label="Telefonnummer*"
                                value={values.telephone ?? ''}
                                touched={touched.telephone}
                                error={errors.telephone}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                id="telephone"
                                name="telephone"
                                type="tel"
                                containerClassName="col-span-4 md:col-span-2"
                            />
                            <TextInput
                                label="Unternehmen mit Firmierung*"
                                value={values.companyNameWithLegalForm ?? ''}
                                touched={touched.companyNameWithLegalForm}
                                error={errors.companyNameWithLegalForm}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                id="companyNameWithLegalForm"
                                name="companyNameWithLegalForm"
                                type="text"
                                containerClassName="col-span-4 md:col-span-2"
                            />
                            <TextInput
                                label="Branche*"
                                value={values.industry ?? ''}
                                touched={touched.industry}
                                error={errors.industry}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                id="industry"
                                name="industry"
                                type="text"
                                containerClassName="col-span-4 md:col-span-2"
                            />
                            <NumberOfEmployeesDropdown
                                disabled={isSubmitting}
                            />
                            <TextInput
                                label="Wie lautet ihre Berufsbezeichnung?*"
                                value={values.jobTitle ?? ''}
                                touched={touched.jobTitle}
                                error={errors.jobTitle}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                id="jobTitle"
                                name="jobTitle"
                                type="text"
                                containerClassName="col-span-4 md:col-span-3"
                            />
                        </div>
                        <div className="w-full flex justify-center">
                            <button
                                type="submit"
                                className="dup-reset font-secondary h-12 border-none bg-orange text-white disabled:opacity-50 col-span-2 p-4 max-w-64 cursor-pointer disabled:cursor-not-allowed"
                                disabled={
                                    isSubmitting ||
                                    !confirmedDataProtection ||
                                    !!errors.email ||
                                    !!errors.firstName ||
                                    !!errors.lastName ||
                                    !!errors.companyNameWithLegalForm ||
                                    !!errors.industry ||
                                    !!errors.jobTitle
                                }
                            >
                                Jetzt Anmeldeprozess starten
                            </button>
                        </div>
                        {error && (
                            <div>
                                <ErrorMessage
                                    error={{ name: error, message: error }}
                                />
                            </div>
                        )}
                    </form>
                )}
            </Formik>
            <div className="my-10">* Pflichtfeld</div>
            <div className="bg-background p-8">
                <h2 className="mb-4">Einwilligungserklärung</h2>
                <p className="mb-8">
                    Vielen Dank für Ihre Anmeldung. Um diese bearbeiten zu
                    können, benötigen wir Ihr Einverständnis:
                </p>
                <div className="flex items-start space-x-2">
                    <input
                        type="checkbox"
                        className="dup-reset font-secondary text-black px-4 py-3 focus-visible:outline-black focus-visible:border-black mt-1"
                        onChange={(event) =>
                            setConfirmedDataProtection(event.target.checked)
                        }
                        checked={confirmedDataProtection}
                    />
                    <div>
                        <p className="mb-4">
                            Hiermit erkläre ich mich damit einverstanden, dass
                            die DUP Media GmbH, ein Tochterunternehmen der JDB
                            Holding GmbH und mit ihr verbundene{' '}
                            <a
                                href={Environment.PURPLE_COMPANY_URL}
                                className="text-blue"
                            >
                                Unternehmen
                            </a>
                            , die von mir eingegebenen Daten zur Durchführung
                            meiner BIG BANG KI Circle-Anmeldung und meiner BIG
                            BANG KI Circle-Mitgliedschaft verarbeiten. Die Daten
                            werden teilweise auch öffentlich auf der BIG BANG KI
                            Circle-Website einsehbar sein und können
                            insbesondere von anderen BIG BANG KI
                            Circle-Mitgliedern eingesehen und genutzt werden.
                            Ich kann diese Einwilligung jederzeit mit Wirkung
                            für die Zukunft widerrufen. Dafür genügt z.B. eine
                            E-Mail an{' '}
                            <a
                                href="mailto:ki-circle@dup-magazin.de"
                                className="text-blue"
                            >
                                ki-circle@dup-magazin.de
                            </a>
                            . Mir ist bewusst, dass in dem Fall ggf. eine
                            Mitgliedschaft nur noch eingeschränkt oder nicht
                            mehr möglich ist.
                        </p>
                        <p className="font-bold">Widerrufsrecht</p>
                        <p className="mb-4">
                            Ich habe das Recht, meine Einwilligung jederzeit zu
                            widerrufen, ohne dass die Rechtmäßigkeit, der
                            aufgrund der Einwilligung bis zum Widerruf erfolgten
                            Verarbeitung berührt wird.
                        </p>
                        <p className="font-bold">
                            Hinweis zur Datenverarbeitung
                        </p>
                        <p className="mb-4">
                            Die DUP Media GmbH wird die von mir angegebenen
                            Daten ausschließlich für die genannten Zwecke
                            verwenden und die Daten nicht an Dritte weitergeben,
                            es sei denn, dies ist gesetzlich vorgeschrieben oder
                            ich habe eine ausdrückliche Zustimmung gegeben.
                        </p>
                        {isGold && (
                            <p className="mb-4">
                                <b>
                                    Der Beitrag für BIG BANG KI Circlelist:innen
                                    beträgt 2000 EUR
                                </b>{' '}
                                (netto). Ihre KI Circle Mitgliedschaft schließen
                                Sie für 12 Monate ab. Die Mitgliedschaft ist für
                                eine Person gültig. Die Mitgliedschaft ist nicht
                                übertragbar.{' '}
                            </p>
                        )}
                        <p>
                            Mit der Übermittlung Ihrer Anmeldedaten zur Prüfung
                            an das KI-Team starten Sie den Anmeldeprozess.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
