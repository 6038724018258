import { RouteState } from '@purple/purple-experience-router.type';

export class RouteChangedEvent extends Event {
    data: RouteState;
    constructor(type: string, data: RouteState) {
        super(type);
        this.data = data;
    }
}

export type RouteListenerFn = (route: string) => void;
