import {
    Dialog as HeadlessDialog,
    DialogBackdrop as HeadlessDialogBackdrop,
    DialogPanel as HeadlessDialogPanel,
    DialogTitle as HeadlessDialogTitle,
    Transition,
    TransitionChild,
} from '@headlessui/react';
import CloseButton from '@components/modals/CloseButton';
import React from 'react';

type DialogProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    dismissible?: boolean;
    title?: string;
    content?: React.JSX.Element;
    showCloseIcon?: boolean;
    actions: React.JSX.Element;
    testId?: string;
};

export default function BaseModal({
    open,
    setOpen,
    dismissible = true,
    title,
    content,
    actions,
    showCloseIcon = dismissible,
    testId,
}: DialogProps) {
    return (
        <Transition show={open} data-testid={testId}>
            <HeadlessDialog
                as="div"
                className="relative z-50"
                onClose={() => dismissible && setOpen(false)}
            >
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <HeadlessDialogBackdrop className="fixed inset-0 bg-black/70" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <HeadlessDialogPanel className="relative transform rounded-lg bg-white border-white border px-6 py-8 text-left transition-all flex flex-col sm:w-full sm:max-w-lg">
                                {title && (
                                    <HeadlessDialogTitle
                                        as="h3"
                                        className="mt-8 font-semibold text-[30px] leading-[38px] text-n600DarkBlue text-center"
                                    >
                                        {title}{' '}
                                    </HeadlessDialogTitle>
                                )}
                                {showCloseIcon && (
                                    <div className="text-right leading-none mb-4">
                                        <CloseButton
                                            onClick={() => setOpen(false)}
                                            tabIndex={0}
                                            className="!text-black"
                                        />
                                    </div>
                                )}
                                {content}
                                {actions}
                            </HeadlessDialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </HeadlessDialog>
        </Transition>
    );
}
