import { Dropzone } from '@components/upload/Dropzone';
import { FileRejection } from 'react-dropzone';
import { UploadProgress } from '@components/upload/UploadProgress';
import { UploadStatus } from '@components/upload/UploadStatus';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useRef,
    useState,
} from 'react';

export type UploadProps<IdType> = {
    readonly fileUploads: FileUpload<IdType>[];
    readonly setFileUploads: Dispatch<SetStateAction<FileUpload<IdType>[]>>;
    readonly uploadFuncDB: (
        file: File,
        progressCallback: (progress: number) => void,
    ) => Promise<IdType>;
    readonly deleteFuncDB: (id: IdType) => Promise<void>;
    readonly acceptedFileFormats?: Record<string, string[]>;
    readonly maxFileSize: number;
    readonly maxFiles?: number;
    readonly disabled?: boolean;
};

export type FileUpload<IdType> = {
    status: UploadStatus;
    name: string;
    size: number;
    /**
     * [0,1]
     */
    progress: number;
    id: number;
    remoteId?: IdType;
    file: File;
};

export function Upload<IdType>({
    fileUploads,
    setFileUploads,
    uploadFuncDB,
    deleteFuncDB,
    maxFileSize,
    acceptedFileFormats,
    maxFiles = Infinity,
    disabled = false,
}: UploadProps<IdType>) {
    const [invalidFiles, setInvalidFiles] = useState<
        (FileRejection & { id: number })[]
    >([]);
    const count = useRef(fileUploads.length);

    const updateUploadStatus = useCallback(
        (id: number, data: Partial<FileUpload<IdType>>) =>
            setFileUploads((fileUploads) =>
                fileUploads.map((fileUpload) =>
                    fileUpload.id === id
                        ? {
                              ...fileUpload,
                              ...data,
                          }
                        : fileUpload,
                ),
            ),
        [setFileUploads],
    );

    const uploadFile = useCallback(
        (files: File[], rejectedFiles?: FileRejection[]) => {
            const newUploads: FileUpload<IdType>[] = files.map((file) => ({
                file,
                status: UploadStatus.UPLOADING,
                name: file.name,
                size: file.size,
                progress: 0,
                id: count.current++,
            }));

            setFileUploads((oldUploads) => [...newUploads, ...oldUploads]);
            if (rejectedFiles?.length) {
                const newRejectedFiles = rejectedFiles.map((file) => ({
                    ...file,
                    id: count.current++,
                }));

                setInvalidFiles((oldRejectedFiles) => [
                    ...newRejectedFiles,
                    ...oldRejectedFiles,
                ]);
            }

            newUploads.forEach((upload) => {
                const { id, file } = upload;
                void uploadFuncDB(file, (progress) =>
                    updateUploadStatus(id, { progress }),
                )
                    .then((remoteId) =>
                        updateUploadStatus(id, {
                            remoteId: remoteId,
                            status: UploadStatus.SUCCESSFUL,
                        }),
                    )
                    .catch((error) => {
                        console.error(error);

                        updateUploadStatus(id, {
                            status: UploadStatus.FAILED,
                        });
                    });
            });
        },
        [setFileUploads, updateUploadStatus, uploadFuncDB],
    );
    const deleteValidFile = useCallback(
        (id: number, deleteRemote: boolean) => {
            let fileToDelete: FileUpload<IdType>;
            const newData = fileUploads.filter((fileUpload) => {
                if (fileUpload.id === id) {
                    fileToDelete = fileUpload;
                    if (deleteRemote && fileToDelete.remoteId) {
                        /*
                        NOTE:
                        We just try to delete it. If it works all is fine.
                        And if it doesn't work, then the file is still there remotely
                        but no longer appears on the upload list, so we don't do anything to it anymore.
                         */
                        void deleteFuncDB(fileToDelete.remoteId);
                    }
                    return undefined;
                } else {
                    return fileUpload;
                }
            });

            setFileUploads(newData);
        },
        [deleteFuncDB, setFileUploads, fileUploads],
    );

    const deleteInvalidFile = useCallback((id: number) => {
        setInvalidFiles((previousInvalid) =>
            previousInvalid.filter((invalidFile) => invalidFile.id !== id),
        );
    }, []);

    const retryUpload = useCallback(
        (id: number) => {
            const newData = fileUploads.find(
                (fileUpload) => fileUpload.id === id,
            );
            if (newData) {
                deleteValidFile(id, false);
                uploadFile([newData.file]);
            }
        },
        [deleteValidFile, uploadFile, fileUploads],
    );

    return (
        <div className="space-y-2">
            {fileUploads.length < maxFiles && (
                <Dropzone
                    onDrop={uploadFile}
                    maxFileSize={maxFileSize}
                    acceptedFileFormats={acceptedFileFormats}
                    maxFiles={maxFiles - fileUploads.length}
                    disabled={disabled}
                />
            )}
            <div className="">
                {invalidFiles.map((file) => {
                    return (
                        <UploadProgress
                            key={file.id}
                            id={file.id}
                            filename={file.file.name}
                            uploadProgress={0}
                            uploadSize={file.file.size}
                            uploadState={UploadStatus.INVALID}
                            deleteCallback={deleteValidFile}
                            retryCallback={retryUpload}
                            deleteInvalidCallback={deleteInvalidFile}
                            rejectionErrorCode={file.errors[0]?.code}
                        />
                    );
                })}
                {fileUploads.map((file) => {
                    return (
                        <UploadProgress
                            key={file.id}
                            id={file.id}
                            filename={file.name}
                            uploadProgress={file.progress}
                            uploadSize={file.size}
                            uploadState={file.status}
                            deleteCallback={deleteValidFile}
                            retryCallback={retryUpload}
                        />
                    );
                })}
            </div>
        </div>
    );
}
