import { Dropdown } from '@components/dropdown';
import { RegisterTierForm } from '@unternehmensverzeichnis/shared/types';
import { getIn, useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';

const fieldName = 'numberOfEmployees';

export const NumberOfEmployeesDropdown = ({
    disabled = false,
}: {
    disabled?: boolean;
}) => {
    const { values, setFieldValue, setFieldTouched } =
        useFormikContext<RegisterTierForm>();

    const value = getIn(values, fieldName) as string;
    const options = useMemo(
        () => ['Keine Angabe', '0-3', '3-10', '10-50', '50-200', 'über 200'],
        [],
    );
    const [selectedOption, setSelectedOption] = useState<string>(
        options.find((option) => option === value) ?? options[0],
    );

    const onChangeHandler = (option: string) => {
        setSelectedOption(option);
        void setFieldValue(fieldName, option);
        void setFieldTouched(fieldName, true);
    };

    return (
        <Dropdown<string>
            options={options}
            onChange={onChangeHandler}
            displayValue={(value) => value}
            value={selectedOption}
            disabled={disabled}
            classNameInput="h-[50px]"
            label="Mitarbeiteranzahl"
        />
    );
};
