import { ReactComponent as CheckIcon } from '../icons/check.svg';
import { ReactComponent as ChevronDownIcon } from '../icons/chevron-down.svg';
import { Listbox, Transition } from '@headlessui/react';
import { classNames } from '@utils/classNames';
import React, { Fragment } from 'react';

export type DropdownProps<Option> = {
    options: readonly Option[];
    onChange: (newValue: Option) => void;
    displayValue: (value: Option) => string;
    value: Option;
    label: string;
    classNameContainer?: string;
    classNameInput?: string;
    disabled?: boolean;
};

export const Dropdown = <T,>({
    value,
    displayValue,
    onChange,
    label,
    classNameInput,
    classNameContainer,
    options,
    disabled = false,
}: DropdownProps<T>) => {
    return (
        <div className={classNameContainer}>
            <Listbox
                value={value}
                onChange={(value) => {
                    onChange(value);
                }}
                disabled={disabled}
            >
                {({ open }) => (
                    <>
                        <Listbox.Label
                            className={classNames(
                                'w-full text-sm font-medium inline-block leading-tight',
                                disabled
                                    ? 'text-disabled'
                                    : // eslint-disable-next-line sonarjs/no-duplicate-string
                                      'text-black',
                            )}
                        >
                            <h3 className="mb-2 font-normal">{label}</h3>
                        </Listbox.Label>
                        <div className={classNames('relative', classNameInput)}>
                            <Listbox.Button
                                className={classNames(
                                    'dup-reset font-secondary px-3.5 py-2.5 border-solid border border-border w-full text-left rounded',
                                    disabled ? '!bg-disabled' : 'bg-white',
                                    classNameInput,
                                )}
                                data-testid="selectBtn"
                            >
                                <span className="block truncate leading-snug">
                                    {displayValue(value)}
                                    {/* This zero-width-space prevents the height from collapsing if the value is empty */}
                                    &#8203;
                                </span>
                                {!disabled && (
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronDownIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                )}
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base border border-border">
                                    {options.map((option, index) => (
                                        <Listbox.Option
                                            key={`${displayValue(
                                                option,
                                            )}-${index}`}
                                            data-testid={`selectOption_${displayValue(
                                                option,
                                            )}`}
                                            className="relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-border"
                                            value={option}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={classNames(
                                                            selected
                                                                ? 'font-semibold'
                                                                : 'font-normal',
                                                            'block truncate min-h-[22px]',
                                                        )}
                                                    >
                                                        {displayValue(option)}
                                                    </span>

                                                    {selected && (
                                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                            <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    );
};
