import { ParsedQs } from 'qs';
import { QueryParams } from '@purple/purple-experience-router.type';

export const convertMapToRecord = (
    map: QueryParams | undefined,
): Record<string, string> | undefined => {
    if (!map) {
        return undefined;
    }
    return Object.fromEntries(map);
};

export const convertRecordToMap = (
    record:
        | Record<string, string | string[]>
        | ParsedQs
        | ParsedQs[]
        | undefined,
): Map<string, string | string[]> | undefined => {
    if (!record) {
        return undefined;
    }
    const map = new Map<string, string | string[]>();
    Object.entries(record).forEach(([key, value]) => {
        if (
            typeof value === 'string' ||
            (Array.isArray(value) && typeof value[0] === 'string')
        ) {
            map.set(key, value as string | string[]);
        } else {
            console.error('non supported purple format found', value);
        }
    });
    return map;
};
