import { classNames } from '@utils/classNames';
import React, { ChangeEventHandler, HTMLInputTypeAttribute } from 'react';

export default function TextInput({
    label,
    value,
    touched,
    error,
    handleBlur,
    handleChange,
    id,
    name,
    type,
    containerClassName,
    inputClassName,
    disabled,
}: Readonly<{
    label: string;
    value: string;
    touched?: boolean;
    error?: string;
    handleBlur: (e: React.FocusEvent<any>) => void;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    type: HTMLInputTypeAttribute;
    name: string;
    id: string;
    containerClassName?: string;
    inputClassName?: string;
    disabled?: boolean;
}>) {
    return (
        <div
            className={classNames(
                'flex flex-col w-full space-y-2',
                containerClassName,
            )}
        >
            <label htmlFor={id} className="text-xl">
                {label}
            </label>
            <input
                id={id}
                type={type}
                name={name}
                className={classNames(
                    'dup-reset font-secondary h-6 text-black px-4 py-3 border-solid border border-border rounded',
                    inputClassName,
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                disabled={disabled}
            />
            {error && touched && <p className="text-error">{error}</p>}
        </div>
    );
}
