import * as React from 'react';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { classNames } from '@utils/classNames';

export enum ButtonType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

export type ButtonProps = {
    label?: string | React.ReactElement;
    buttonType: ButtonType;
    disabled?: boolean;
    loading?: boolean;
    labelClassName?: string;
    testId?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const types: Record<ButtonType, { main: string; icon?: string }> = {
    [ButtonType.PRIMARY]: {
        main: 'text-white bg-blue rounded-xl disabled:bg-mbGreenLight gap-2 px-4 py-3 hover:brightness-90 disabled:opacity-60',
    },
    [ButtonType.SECONDARY]: {
        main: 'bg-white rounded-xl gap-2 px-4 py-3 border border-border hover:brightness-90 disabled:opacity-60',
    },
};

export function Button({
    label,
    buttonType,
    disabled = false,
    loading = false,
    testId,
    type = 'button',
    labelClassName,
    ...rest
}: ButtonProps) {
    return (
        <button
            {...rest}
            type={type}
            className={classNames(
                'dup-reset font-secondary h-12 border-none disabled:border-solid',
                'inline-flex relative items-center font-semibold',
                !loading && 'enabled:active:translate-y-0.5',
                'disabled:cursor-not-allowed disabled:!drop-shadow-none',
                loading && 'cursor-default',
                types[buttonType].main,
                rest.className,
            )}
            disabled={!!disabled}
            onClick={disabled || loading ? undefined : rest.onClick}
            {...(testId ? { 'data-testid': testId } : {})}
        >
            {loading && (
                <LoadingSpinner className="h-5 absolute inset-x-0 inset-y-auto" />
            )}
            {
                <div
                    className={classNames(
                        'w-full inline-flex relative items-center justify-center gap-x-3',
                        loading && 'invisible',
                    )}
                >
                    {label && <div className={labelClassName}>{label}</div>}
                </div>
            }
        </button>
    );
}
