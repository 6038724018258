import {
    RouteState,
    RouteStateChanged,
    changeRouteListener,
} from '@purple/purple-experience-router.type';
import { convertRecordToMap } from '@utils/map-record-transformer';
import { purple } from './purple-api';
import qs from 'qs';

export type PurpleWindow = {
    purple?: typeof purple;
} & typeof window;

export class PurpleApiAdapter {
    private readonly purpleApi: typeof purple;

    private routeListeners: changeRouteListener[];

    private purpleListener = (event: RouteStateChanged) => {
        this.routeListeners.forEach((listener) => {
            listener(event);
        });
    };

    constructor() {
        const purpleWindow = window as PurpleWindow;
        if (!purpleWindow.purple) {
            console.log('did not find purple, using debug client');
            purpleWindow.purple = purple;
        }
        this.purpleApi = purpleWindow.purple;
        this.routeListeners = [];

        void purpleWindow.purple.experience.router.addRouteChangeListener(
            this.purpleListener,
        );
    }

    getCurrentRoute = async (): Promise<RouteState> => {
        return await this.purpleApi.experience.router.getCurrentRoute();
    };

    addRouteListener(listenerFn: changeRouteListener): void {
        this.routeListeners.push(listenerFn);
    }

    sendUrlChangeToPurple(newUrl: string, queryParams: string) {
        void this.purpleApi.experience.router.changeRoute({
            path: newUrl,
            queryParams: convertRecordToMap(
                qs.parse(queryParams.replace('?', '')),
            ),
            stateSender: 'widget',
        });
    }
}

const purpleApiAdapter = new PurpleApiAdapter();
export default purpleApiAdapter;
