import {
    AbortMultipartUploadCommand,
    CompleteMultipartUploadCommand,
    CreateMultipartUploadCommand,
    DeleteObjectCommand,
    GetObjectCommand,
    PutObjectCommand,
    S3Client,
    UploadPartCommand,
} from '@aws-sdk/client-s3';
import { Environment } from '../env';
import { splitAtLastOccurrence } from '@utils/split-at-last-occurrence';
import { v4 as uuidv4 } from 'uuid';

export type AwsFile = {
    id: string;
    name: string;
    size: number;
    url: string;
};

// Note: AWS does cast all metadata keys to lowercase

class AwsS3Client {
    protected client: S3Client;
    // Multipart uploads require a minimum size of ~ 5 MB per part.
    protected minimumChunkSize = 5242880;

    constructor(isTest = false, mockClient?: unknown) {
        if (isTest) {
            if (mockClient) {
                this.client = mockClient as S3Client;
            } else {
                throw new Error('Mock client must be provided for testing');
            }
        } else {
            this.client = new S3Client({
                credentials: {
                    accessKeyId: Environment.AWS_ACCESS_KEY_ID,
                    secretAccessKey: Environment.AWS_SECRET_ACCESS_KEY,
                },
                region: 'eu-central-1',
            });
        }
    }

    async uploadFile(
        file: File,
        bucketName: string,
    ): Promise<AwsFile | undefined> {
        const fileName = file.name;
        console.debug(`trying to store ${file.name} in ${bucketName}`);
        const fileType = file.type.split('/')[1];
        const uploadId = `${uuidv4()}.${fileType}`;
        const resultUrl = `https://s3.eu-central-1.amazonaws.com/${bucketName}/${uploadId}`;
        if (file.size < this.minimumChunkSize) {
            console.debug(
                `file ${fileName} is too small for multipart upload. Using simple upload.`,
            );

            const command = new PutObjectCommand({
                Bucket: bucketName,
                Key: uploadId,
                Body: file,
                ContentType: fileType,
                Metadata: { filename: fileName, size: file.size.toString() },
            });

            try {
                await this.client.send(command);
                return {
                    id: uploadId,
                    name: fileName,
                    size: file.size,
                    url: resultUrl,
                };
            } catch (err) {
                console.error(
                    `error while uploading small file ${fileName} ${err}`,
                );
            }
            return undefined;
        } else {
            let multiPartUploadId;
            try {
                const multipartUpload = await this.client.send(
                    new CreateMultipartUploadCommand({
                        Bucket: bucketName,
                        Key: uploadId,
                        ContentType: fileType,
                        Metadata: {
                            filename: fileName,
                            size: file.size.toString(),
                        },
                    }),
                );

                multiPartUploadId = multipartUpload.UploadId;

                const uploadPromises = [];
                const chunkNumber = Math.floor(
                    file.size / this.minimumChunkSize,
                );
                const partSize = Math.ceil(file.size / chunkNumber);

                // Upload each part.
                for (let i = 0; i < chunkNumber; i++) {
                    const start = i * partSize;
                    const end = start + partSize;
                    uploadPromises.push(
                        this.client
                            .send(
                                new UploadPartCommand({
                                    Bucket: bucketName,
                                    Key: uploadId,
                                    UploadId: multiPartUploadId,
                                    Body: file.slice(start, end),
                                    PartNumber: i + 1,
                                }),
                            )
                            .then((d) => {
                                console.debug(
                                    `Part ${
                                        i + 1
                                    } uploaded for file: ${fileName}`,
                                );
                                return d;
                            }),
                    );
                }

                const uploadResults = await Promise.all(uploadPromises);

                await this.client.send(
                    new CompleteMultipartUploadCommand({
                        Bucket: bucketName,
                        Key: uploadId,
                        UploadId: multiPartUploadId,
                        MultipartUpload: {
                            Parts: uploadResults.map(({ ETag }, i) => ({
                                ETag,
                                PartNumber: i + 1,
                            })),
                        },
                    }),
                );
                return {
                    id: uploadId,
                    name: fileName,
                    size: file.size,
                    url: resultUrl,
                };
            } catch (err) {
                console.error(`error while uploading file ${fileName} ${err}`);

                if (multiPartUploadId) {
                    const abortCommand = new AbortMultipartUploadCommand({
                        Bucket: bucketName,
                        Key: uploadId,
                        UploadId: multiPartUploadId,
                    });

                    await this.client.send(abortCommand);
                }
                return undefined;
            }
        }
    }

    deleteFile = async (fileId: string, bucketName: string) => {
        const command = new DeleteObjectCommand({
            Bucket: bucketName,
            Key: fileId,
        });
        try {
            await this.client.send(command);
        } catch (err) {
            console.error(`error while deleting file ${fileId} ${err}`);
        }
    };

    getFile = async (
        fileUrl: string,
        bucketName: string,
    ): Promise<AwsFile | undefined> => {
        const id = splitAtLastOccurrence(fileUrl, '/')[1];
        const command = new GetObjectCommand({
            Bucket: bucketName,
            Key: id,
        });
        try {
            const result = await this.client.send(command);
            if (!result.Metadata) {
                return undefined;
            }

            return {
                id: id,
                name: result.Metadata.filename,
                size: parseInt(result.Metadata.size),
                url: fileUrl,
            };
        } catch (err) {
            console.error(`error while getting file ${id} ${err}`);
            return undefined;
        }
    };
}

export const awsS3Client = new AwsS3Client(Environment.isTest);
