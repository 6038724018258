import { ErrorMessage } from '@components/errorMessage';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { IndustryDropdown } from '@components/industryDropdown';
import {
    OrganizationContactDTO,
    OrganizationDTO,
} from '@unternehmensverzeichnis/shared/types';
import { UploadCompanyLogo } from '@components/upload/UploadCompanyLogo';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import TextAreaInput from '@components/textAreaInput';
import TextInput from '@components/textInput';
import useGetOrganization from '@hooks/useGetOrganization';
import useSaveOrganization from '@hooks/useSaveOrganization';

function ContactInputs({
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
}: {
    values: OrganizationDTO;
    touched: FormikTouched<OrganizationDTO>;
    errors: FormikErrors<OrganizationDTO>;
    handleBlur: (e: React.FocusEvent<any>) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
}) {
    const contactValue = values['mainContact'];
    const contactTouched = touched['mainContact'] as unknown as
        | FormikTouched<OrganizationContactDTO>
        | undefined;
    const contactErrors = errors['mainContact'] as
        | FormikErrors<OrganizationContactDTO>
        | undefined;

    return (
        <>
            <TextInput
                label="Vorname"
                value={contactValue?.firstName ?? ''}
                touched={contactTouched?.firstName}
                error={contactErrors?.firstName}
                handleBlur={handleBlur}
                handleChange={handleChange}
                id={'mainContact.firstName'}
                name={'mainContact.firstName'}
                type="text"
                containerClassName="col-span-2 md:col-span-1"
            />
            <TextInput
                label="Nachname"
                value={contactValue?.lastName ?? ''}
                touched={contactTouched?.lastName}
                error={contactErrors?.lastName}
                handleBlur={handleBlur}
                handleChange={handleChange}
                id={'mainContact.lastName'}
                name={'mainContact.lastName'}
                type="text"
                containerClassName="col-span-2 md:col-span-1"
            />
            <TextInput
                label="E-Mail"
                value={contactValue?.email ?? ''}
                touched={contactTouched?.email}
                error={contactErrors?.email}
                handleBlur={handleBlur}
                handleChange={handleChange}
                id={'mainContact.email'}
                name={'mainContact.email'}
                type="text"
                containerClassName="col-span-2 md:col-span-1"
            />
        </>
    );
}

export default function EditOrganizationPage() {
    const [params] = useSearchParams();
    const token = params.get('token');
    const { isPending, error, data } = useGetOrganization(token);

    const saveOrganization = useSaveOrganization(token);

    if (error) {
        return <ErrorMessage error={error} />;
    }
    if (isPending) {
        return <p>Wird geladen...</p>;
    }
    return (
        <>
            <h1>Unternehmen</h1>
            <Formik
                initialValues={data}
                validate={(values) => {
                    const errors: {
                        email?: string;
                        name?: string;
                        publicContactEmail?: string;
                        publicContactFirstName?: string;
                        publicContactLastName?: string;
                    } = {};
                    if (!values.mainContact?.email) {
                        errors.email = 'E-Mail darf nicht leer sein.';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.mainContact.email,
                        )
                    ) {
                        errors.email = 'Invalide E-Mail Adresse';
                    }
                    if (!values.name) {
                        errors.name = 'Name darf nicht leer sein.';
                    }

                    if (!values.publicContactFirstName) {
                        errors.publicContactFirstName =
                            'Vorname darf nicht leer sein.';
                    }
                    if (!values.publicContactLastName) {
                        errors.publicContactLastName =
                            'Nachname darf nicht leer sein.';
                    }
                    if (!values.publicContactEmail) {
                        errors.publicContactEmail =
                            'E-Mail darf nicht leer sein.';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    saveOrganization(values).finally(() =>
                        setSubmitting(false),
                    );
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="grid grid-cols-2 gap-4 space-y-2 w-full my-4"
                    >
                        <TextInput
                            label="Name"
                            value={values.name}
                            touched={touched.name}
                            error={errors.name}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="name"
                            name="name"
                            type="text"
                            containerClassName="col-span-2"
                        />
                        <TextInput
                            label="Straße"
                            value={values.street ?? ''}
                            touched={touched.street}
                            error={errors.street}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="street"
                            name="street"
                            type="text"
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <TextInput
                            label="Hausnummer"
                            value={values.streetNumber ?? ''}
                            touched={touched.streetNumber}
                            error={errors.streetNumber}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="streetNumber"
                            name="streetNumber"
                            type="text"
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <TextInput
                            label="PLZ"
                            value={values.postalCode ?? ''}
                            touched={touched.postalCode}
                            error={errors.postalCode}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="postalCode"
                            name="postalCode"
                            type="text"
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <TextInput
                            label="Ort"
                            value={values.city ?? ''}
                            touched={touched.city}
                            error={errors.city}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="city"
                            name="city"
                            type="text"
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <TextInput
                            label="Telefonnummer"
                            value={
                                values.telephone ??
                                values.mainContact?.telephone ??
                                ''
                            }
                            touched={touched.telephone}
                            error={errors.telephone}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="telephone"
                            name="telephone"
                            type="tel"
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <TextInput
                            label="Website"
                            value={values.website ?? ''}
                            touched={touched.website}
                            error={errors.website}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="website"
                            name="website"
                            type="url"
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <UploadCompanyLogo
                            value={values.companyLogoUrl}
                            setValue={(url: string | null) =>
                                void setFieldValue('companyLogoUrl', url)
                            }
                            updateCompanyLogo={saveOrganization}
                            initialValues={data}
                        />
                        <IndustryDropdown disabled={isSubmitting} />
                        <TextAreaInput
                            label="Unternehmens-Info"
                            value={
                                values.description ??
                                'Musterbeschreibungstext als Vorlage für Ihre Kunden:\n\n' +
                                    '[Unternehmensname] mit Sitz in [Standort] wurde im Jahr [Gründungsjahr] gegründet und ist spezialisiert auf [Branche].\n' +
                                    'Unser Hauptfokus liegt auf [Produkte/Dienstleistungen], wobei wir vor allem [Zielgruppe] bedienen.\n' +
                                    'Unser USP liegt in [Alleinstellungsmerkmal], wodurch wir uns von anderen Anbietern abheben.\n' +
                                    'Getreu unserer Werte [Werte, Unternehmensphilosophie] verfolgen wir kontinuierlich das Ziel, [Vision].'
                            }
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            name="description"
                            id="description"
                            containerClassName="col-span-2"
                            inputClassName="h-64"
                        />
                        <h2 className="col-span-2">Firmen-Kontakt</h2>
                        <ContactInputs
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        {/* empty object to have an empty cell */}
                        <div className="hidden md:block col-span-1" />
                        <h2 className="col-span-2">
                            Öffentlich dargestellter Kontakt
                        </h2>
                        <TextInput
                            label="Vorname"
                            value={values.publicContactFirstName ?? ''}
                            touched={touched.publicContactFirstName}
                            error={errors.publicContactFirstName}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="publicContactFirstName"
                            name="publicContactFirstName"
                            type="text"
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <TextInput
                            label="Nachname"
                            value={values.publicContactLastName ?? ''}
                            touched={touched.publicContactLastName}
                            error={errors.publicContactLastName}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="publicContactLastName"
                            name="publicContactLastName"
                            type="text"
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <TextInput
                            label="Email"
                            value={values.publicContactEmail ?? ''}
                            touched={touched.publicContactEmail}
                            error={errors.publicContactEmail}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            id="publicContactEmail"
                            name="publicContactEmail"
                            type="text"
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <button
                            type="submit"
                            className="dup-reset font-secondary h-12 border-none disabled:border-solid bg-orange text-white disabled:opacity-50 col-span-2 p-4 max-w-64"
                            disabled={isSubmitting}
                        >
                            Speichern
                        </button>
                    </form>
                )}
            </Formik>
        </>
    );
}
