import {
    FetchError,
    InvalidMailError,
    MailNotFoundError,
    TokenExpired,
    TokenInvalid,
    TokenMissing,
} from '@unternehmensverzeichnis/shared/errors';
import React from 'react';

function getErrorMessage(error: Error) {
    switch (error.message) {
        case TokenExpired:
            return 'Ihr Zugang ist abgelaufen. Bitte melden Sie sich erneut an.';
        case TokenMissing:
            return 'Kein Zugangstoken gefunden. Bitte melden Sie sich an.';
        case TokenInvalid:
            return 'Ungültiges Zugangstoken. Bitte melden Sie sich erneut an.';
        case FetchError:
            return 'Fehler beim Abrufen der Daten. Bitte versuchen Sie es erneut.';
        case MailNotFoundError:
            return 'Die angegebene E-Mail-Adresse ist nicht hinterlegt. Bitte verwenden Sie die E-Mail-Adresse, die Sie bei uns hinterlegt haben, oder kontaktieren Sie uns direkt.';
        case InvalidMailError:
            return 'Die angegebene E-Mail-Adresse ist ungültig.';
        default:
            return 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
    }
}

export function ErrorMessage({ error }: { error: Error }) {
    return (
        <div>
            <h1>Ein Fehler ist aufgetreten</h1>
            <div className="text-error">{getErrorMessage(error)}</div>
        </div>
    );
}
