import './fonts.css';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { createRouter } from './routes';
import DefaultPage from '@pages/index';
import React from 'react';
import reportWebVitals from './reportWebVitals';

const router = createRouter();

const root = createRoot(
    document.getElementById('purple-widget-unternehmensverzeichnis')!,
);
root.render(
    <React.StrictMode>
        <DefaultPage>
            <RouterProvider router={router} />
        </DefaultPage>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
