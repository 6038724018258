import { Environment } from '../env';
import { OrganizationDTO } from '@unternehmensverzeichnis/shared/types';
import {
    TokenExpired,
    TokenInvalid,
    TokenMissing,
} from '@unternehmensverzeichnis/shared/errors';
import { useQuery } from '@tanstack/react-query';

export default function useGetOrganization(token: string | null) {
    return useQuery({
        queryKey: ['organization', { id: token }],
        queryFn: async () => {
            const response = await fetch(
                `${Environment.BACKEND_BASE_URL}/getOrganization?token=${token}`,
            );
            if (!response.ok) {
                throw new Error(await response.text());
            }
            const companyResult = (await response.json()) as OrganizationDTO;
            if (companyResult.companyLogoUrl) {
                companyResult.companyLogoUrl = decodeURI(
                    companyResult.companyLogoUrl,
                );
            }
            return companyResult;
        },
        retry: (failureCount, error) => {
            if (
                error instanceof Error &&
                (error.message === TokenExpired ||
                    error.message === TokenInvalid ||
                    error.message === TokenMissing)
            ) {
                return false;
            }
            return failureCount < 3;
        },
    });
}
