import React from 'react';

export default function ThanksPage() {
    return (
        <div className="w-full text-center">
            <h1 className="mb-4">Vielen Dank!</h1>
            <p>Ihre Anmeldung ist bei uns eingegangen!</p>
        </div>
    );
}
