import { ErrorCode } from 'react-dropzone';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { UploadStatus } from '@components/upload/UploadStatus';
import { classNames } from '@utils/classNames';
import React, { PropsWithChildren } from 'react';
import TrashButton from '@components/upload/TrashButton';

const defaultColors = {
    filenameTextClass: 'text-black',
    progressTextClass: 'text-disabled',
    progressBackgroundClass: 'bg-white',
};

/* eslint-disable sonarjs/no-duplicate-string*/
const colors: Record<
    UploadStatus,
    {
        filenameTextClass: string;
        progressTextClass: string;
        progressBackgroundClass: string;
    }
> = {
    [UploadStatus.UPLOADING]: defaultColors,
    [UploadStatus.SUCCESSFUL]: defaultColors,
    [UploadStatus.FAILED]: {
        filenameTextClass: 'text-error',
        progressTextClass: 'text-error-40',
        progressBackgroundClass: 'bg-error-40',
    },
    [UploadStatus.INVALID]: {
        filenameTextClass: 'text-error',
        progressTextClass: 'text-error-40',
        progressBackgroundClass: 'bg-error-40',
    },
    [UploadStatus.PREEXISTING]: {
        ...defaultColors,
        progressTextClass: 'hidden',
    },
} as const;
/* eslint-enable sonarjs/no-duplicate-string*/

export type UploadProp = PropsWithChildren<{
    uploadState: UploadStatus;
    uploadSize: number;
    uploadProgress: number;
    filename: string;
    id: number;
    deleteCallback: (id: number, alreadyUploaded: boolean) => void;
    retryCallback: (id: number) => void;
    deleteInvalidCallback?: (id: number) => void;
    rejectionErrorCode?: ErrorCode | string;
}>;

export function UploadProgress({
    id,
    uploadState,
    uploadProgress,
    filename,
    deleteCallback,
    deleteInvalidCallback,
    retryCallback,
    rejectionErrorCode,
}: UploadProp) {
    function showIcon(uploadState: UploadStatus) {
        switch (uploadState) {
            case UploadStatus.SUCCESSFUL:
            case UploadStatus.PREEXISTING: {
                return (
                    <TrashButton
                        onClick={(event) => {
                            event.stopPropagation();
                            deleteCallback(id, true);
                        }}
                        testId="delete-successful"
                    />
                );
            }
            case UploadStatus.UPLOADING:
                return <LoadingSpinner className="h-5 w-5" />;

            case UploadStatus.FAILED:
            case UploadStatus.INVALID:
                return (
                    <TrashButton
                        onClick={(event) => {
                            event.stopPropagation();
                            uploadState === UploadStatus.INVALID &&
                            deleteInvalidCallback
                                ? deleteInvalidCallback(id)
                                : deleteCallback(id, false);
                        }}
                        testId="delete-failed"
                    />
                );
        }
    }

    /* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
    function getErrorMessageKey(errorCode: ErrorCode | string) {
        switch (errorCode) {
            case ErrorCode.FileTooLarge:
                return 'fileTooLarge';
            case ErrorCode.FileInvalidType:
                return 'unknownFileType';
            case ErrorCode.TooManyFiles:
                return 'tooManyFiles';
            default:
                return 'unknownError';
        }
    }
    /* eslint-enable @typescript-eslint/no-unsafe-enum-comparison */

    const dotIndex = filename.lastIndexOf('.');
    const extension = dotIndex >= 0 ? filename.slice(dotIndex) : null;
    const filenameWithoutExtension = filename.slice(0, dotIndex);

    return (
        <div
            tabIndex={-1}
            className={classNames(
                uploadState === UploadStatus.SUCCESSFUL ||
                    uploadState === UploadStatus.PREEXISTING ||
                    uploadState === UploadStatus.UPLOADING
                    ? 'col-span-1'
                    : 'col-span-2',
                'min-h-10 overflow-hidden relative border-solid border-border border',
                colors[uploadState].progressBackgroundClass,
            )}
            data-testid="upload-progress"
            title={filename}
        >
            <div
                className={classNames(
                    colors[uploadState].progressBackgroundClass,
                    'absolute inset-0 h-full transition-all duration-250 ease-linear',
                )}
                style={{
                    width: `${uploadProgress * 100}%`,
                }}
            />
            <div className="relative flex flex-col w-full p-2">
                <div
                    className={classNames(
                        colors[uploadState].filenameTextClass,
                        'flex items-center flex-row flex-nowrap w-full justify-center',
                        'text-sm font-medium',
                    )}
                >
                    <div className="flex-shrink min-w-0 flex overflow-hidden">
                        <span className="truncate">
                            {filenameWithoutExtension}
                        </span>
                        {/* eslint-disable-next-line eqeqeq */}
                        {extension != null && (
                            <span className="flex-shrink-0">{extension}</span>
                        )}
                    </div>
                    <div className="flex-grow" />
                    <div className="flex-shrink-0">{showIcon(uploadState)}</div>
                </div>
                {rejectionErrorCode && (
                    <p
                        className={classNames(
                            colors[uploadState].progressTextClass,
                            'mt-0.5 text-sm',
                        )}
                    >
                        <span>
                            Abgelehnt: {getErrorMessageKey(rejectionErrorCode)}
                        </span>
                    </p>
                )}
                {uploadState === UploadStatus.FAILED && (
                    <div className="w-full flex justify-end">
                        <button
                            className={classNames(
                                'dup-reset font-secondary h-12 border-none disabled:border-solid',
                                colors[uploadState].filenameTextClass,
                                'flex bg-orange text-white rounded p-1 mt-1 text-sm font-medium cursor-pointer text-center h-[22px] items-center',
                            )}
                            onClick={(event) => {
                                event.stopPropagation();
                                retryCallback(id);
                            }}
                            data-testid="retry-button"
                        >
                            Nochmal versuchen
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
