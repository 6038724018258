import { Environment } from '../env';
import { OrganizationDTO } from '@unternehmensverzeichnis/shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useSaveOrganization(token: string | null) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (newData: OrganizationDTO) => {
            if (newData.companyLogoUrl) {
                newData.companyLogoUrl = encodeURI(newData.companyLogoUrl);
            }
            return await fetch(
                `${Environment.BACKEND_BASE_URL}/updateOrganization?token=${token}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newData),
                },
            );
        },
        onSuccess: (data) => {
            queryClient.setQueryData(['organization', { id: token }], data);
        },
    }).mutateAsync;
}
