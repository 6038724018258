import { Button, ButtonProps } from '@components/Button';
import { classNames } from '@utils/classNames';
import BaseModal from '@components/modals/BaseModal';
import React, { PropsWithChildren } from 'react';

type DialogProps = PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    dismissible?: boolean;
    title?: string;
    actions: { buttonProps: ButtonProps }[];
    actionContainerClassNames?: string;
    testId?: string;
}>;

export default function Dialog({
    open,
    setOpen,
    dismissible = true,
    title,
    children,
    actions,
    actionContainerClassNames,
    testId,
}: DialogProps) {
    return (
        <BaseModal
            open={open}
            setOpen={setOpen}
            dismissible={dismissible}
            title={title}
            content={
                children ? (
                    <div className="mt-2 text-sm">{children}</div>
                ) : undefined
            }
            actions={
                <div
                    className={classNames(
                        'mt-5 sm:mt-4 grid sm:grid-cols-2 grid-flow-row-dense gap-x-4 gap-y-2',
                        actionContainerClassNames,
                    )}
                    style={{ direction: 'rtl' }}
                >
                    {actions.map((action, index) => (
                        <Button key={index} {...action.buttonProps} />
                    ))}
                </div>
            }
            testId={testId}
        />
    );
}
