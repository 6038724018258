import { Dropdown } from '@components/dropdown';
import {
    IndustryOption,
    OrganizationDTO,
} from '@unternehmensverzeichnis/shared/types';
import { getIn, useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';

const fieldName = 'industryID';

export const IndustryDropdown = ({
    disabled = false,
}: {
    disabled?: boolean;
}) => {
    const { values, setFieldValue, setFieldTouched } =
        useFormikContext<OrganizationDTO>();

    const value = getIn(values, fieldName) as string;
    const options = useMemo(
        () => [
            { id: null, label: 'Keine Branche' },
            ...(getIn(values, 'industryOptions') as IndustryOption[]),
        ],
        [values.industryOptions],
    );
    const [selectedOption, setSelectedOption] = useState<IndustryOption | null>(
        options.find((option) => option.id === Number(value)) ?? null,
    );

    const onChangeHandler = (option: IndustryOption | null) => {
        setSelectedOption(option);
        void setFieldValue(fieldName, option?.id);
        void setFieldTouched(fieldName, true);
    };

    return (
        <Dropdown<IndustryOption | null>
            options={options}
            onChange={onChangeHandler}
            displayValue={(value) => value?.label ?? 'Keine Branche'}
            value={selectedOption}
            disabled={disabled}
            classNameInput="h-16"
            label="Branche"
        />
    );
};
