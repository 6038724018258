import { classNames } from '@utils/classNames';
import React from 'react';

type CloseButtonProps = {
    onClick: () => void;
    className?: string;
    testId?: string;
};

export default function CloseButton({
    className,
    onClick,
    testId,
    ...rest
}: CloseButtonProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>) {
    return (
        <button
            {...rest}
            className={classNames(
                'dup-reset font-secondary h-12 border-none disabled:border-solid',
                className,
                'bg-white hover:bg-background active:bg-background',
                'rounded',
            )}
            onClick={onClick}
            {...(testId ? { 'data-testid': testId } : {})}
        >
            X
        </button>
    );
}
